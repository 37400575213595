import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Mermaid } from "src/components/Mermaid";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "kubernetes-and-ci"
    }}>{`Kubernetes and CI`}</h1>
    <Mermaid mdxType="Mermaid">
	graph LR; pr(Pull Request)-->test(Run Tests); test-->staging(Deploy to
	Staging); staging-->manual(Manual Exploratory Testing); manual-->merge(Merge
	Pull Request); merge-->prod(Deploy to Prod);
    </Mermaid>
    <h2 {...{
      "id": "docker"
    }}>{`Docker`}</h2>
    <ul>
      <li parentName="ul">{`Standard way of shipping services`}<ul parentName="li">
          <li parentName="ul">{`Can easily get a database or prometheus service as a container`}</li>
        </ul></li>
      <li parentName="ul">{`Can deploy the full stack locally with docker-compose`}</li>
      <li parentName="ul">{`Infrastructure as code is nice`}</li>
      <li parentName="ul">{`We use testcontainers which need to run using docker run, `}<a parentName="li" {...{
          "href": "https://github.com/testcontainers/testcontainers-java/issues/1112"
        }}>{`not docker build`}</a><ul parentName="li">
          <li parentName="ul">{`Solution is to stop the build after compilation then run the tests then complete the build while skipping those tests`}</li>
        </ul></li>
      <li parentName="ul">{`Multi stage builds`}<ul parentName="li">
          <li parentName="ul">{`Use multi stage builds to reduce container sizes by excluding the dependencies required for building`}<ul parentName="li">
              <li parentName="ul">{`Previously people used a builder image for this which is what older blog posts reference`}</li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://whitfin.io/speeding-up-maven-docker-builds/"
            }}>{`How to do it in Java`}</a></li>
          <li parentName="ul">{`Use Alpine images because they're smaller`}</li>
          <li parentName="ul">{`Caching multi stage builds - Speeds up build time dramatically - It's tricky - `}<a parentName="li" {...{
              "href": "https://github.com/moby/moby/issues/34715"
            }}>{`https://github.com/moby/moby/issues/34715`}</a>{` - `}<a parentName="li" {...{
              "href": "https://stackoverflow.com/questions/52646303/is-it-possible-to-cache-multi-stage-docker-builds"
            }}>{`https://stackoverflow.com/questions/52646303/is-it-possible-to-cache-multi-stage-docker-builds`}</a>{` - `}<a parentName="li" {...{
              "href": "https://andrewlock.net/caching-docker-layers-on-serverless-build-hosts-with-multi-stage-builds---target,-and---cache-from/"
            }}>{`https://andrewlock.net/caching-docker-layers-on-serverless-build-hosts-with-multi-stage-builds---target,-and---cache-from/`}</a></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "kubernetes"
    }}>{`Kubernetes`}</h2>
    <ul>
      <li parentName="ul">{`3 services`}<ul parentName="li">
          <li parentName="ul">{`DB: MySQL database`}</li>
          <li parentName="ul">{`API: Spring Boot API`}</li>
          <li parentName="ul">{`NGINX: Serving frontend assets and proxying the api`}</li>
        </ul></li>
    </ul>
    <p>{`Static Assets`}</p>
    <Mermaid mdxType="Mermaid">
	sequenceDiagram; client->>Ingress: https request; Ingress->>NGINX: http
	request; NGINX->>Ingress: static frontend assets; Ingress->>client: https
	static frontend assets;
    </Mermaid>
    <p>{`API requests`}</p>
    <Mermaid mdxType="Mermaid">
	sequenceDiagram; client->>Ingress: https api request; Ingress->>NGINX: http
	api request; NGINX->>API: http api request; API->>DB: query; DB->>API: table
	results; API->>NGINX: json response; NGINX->>Ingress: gzip json response;
	Ingress->>client: https gzip json response;
    </Mermaid>
    <ul>
      <li parentName="ul">{`It's nice to have a platform where you can focus on services instead of servers`}<ul parentName="li">
          <li parentName="ul">{`Easy to deploy another service like a redis cache or prometheus for monitoring`}</li>
          <li parentName="ul">{`Deploying locally is pretty close to the production environment so it's easier to reproduce issues`}</li>
        </ul></li>
      <li parentName="ul">{`Use `}<a parentName="li" {...{
          "href": "https://docs.docker.com/compose/"
        }}>{`docker-compose`}</a>{` for local development.`}</li>
      <li parentName="ul">{`Docker for Mac has kubernetes now so there's no need for minikube`}<ul parentName="li">
          <li parentName="ul">{`You just need to enable it`}</li>
          <li parentName="ul">{`Integrated with your existing docker credentials`}</li>
        </ul></li>
      <li parentName="ul">{`I used `}<a parentName="li" {...{
          "href": "https://kompose.io/"
        }}>{`Kompose`}</a>{` to convert our docker-compose file to Kubernetes resources`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`brew install kompose`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`docker-compose build`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`kompose up --build none`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`kompose convert`}</inlineCode></li>
          <li parentName="ul">{`Kompose can also create Helm files too`}</li>
          <li parentName="ul">{`docker-compose is also `}<a parentName="li" {...{
              "href": "https://github.com/docker/compose-on-kubernetes"
            }}>{`available in Kubernetes`}</a>{` now but that's a whole other thing`}</li>
        </ul></li>
      <li parentName="ul">{`Deploying with Helm vs kubectl vs other`}<ul parentName="li">
          <li parentName="ul">{`Chose kubectl because it was easy and is ready our of the box.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://kubernetes.io/docs/tasks/run-application/run-stateless-application-deployment/"
            }}>{`kubectl`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://helm.sh"
            }}>{`Helm`}</a><ul parentName="li">
              <li parentName="ul">{`Have to install it on your cluster`}</li>
              <li parentName="ul">{`Helm 3 is on the way`}</li>
              <li parentName="ul">{`Potential `}<a parentName="li" {...{
                  "href": "https://medium.com/virtuslab/think-twice-before-using-helm-25fbb18bc822"
                }}>{`security implications`}</a>{` I didn't want to deal with`}</li>
            </ul></li>
          <li parentName="ul">{`There's heaps of other `}<a parentName="li" {...{
              "href": "https://blog.argoproj.io/the-state-of-kubernetes-configuration-management-d8b06c1205"
            }}>{`Kubernetes Configuration tools`}</a>{` too`}</li>
        </ul></li>
      <li parentName="ul">{`Used sed to specify the docker image to deploy based on the commit hash`}</li>
      <li parentName="ul">{`Ingress: `}<a parentName="li" {...{
          "href": "https://github.com/kubernetes/ingress-gce"
        }}>{`GCE`}</a>{` vs `}<a parentName="li" {...{
          "href": "https://github.com/kubernetes/ingress-nginx"
        }}>{`NGINX`}</a><ul parentName="li">
          <li parentName="ul">{`GCE`}<ul parentName="li">
              <li parentName="ul">{`Benefits`}<ul parentName="li">
                  <li parentName="ul">{`Easy certificates`}</li>
                </ul></li>
              <li parentName="ul">{`Drawbacks`}<ul parentName="li">
                  <li parentName="ul">{`More vendor lockin`}</li>
                  <li parentName="ul">{`Can't redirect from http to https`}<ul parentName="li">
                      <li parentName="ul"><a parentName="li" {...{
                          "href": "https://serverfault.com/questions/502733/rewrite-http-to-https-with-ngnix-behind-load-balancer"
                        }}>{`Used NGINX for this`}</a></li>
                    </ul></li>
                </ul></li>
            </ul></li>
          <li parentName="ul">{`NGINX`}<ul parentName="li">
              <li parentName="ul">{`Benefits`}<ul parentName="li">
                  <li parentName="ul">{`Less vendor lockin`}</li>
                  <li parentName="ul">{`Can redirect to https - Can be used across namespaces`}</li>
                </ul></li>
              <li parentName="ul">{`Drawbacks`}<ul parentName="li">
                  <li parentName="ul">{`Requires `}<a parentName="li" {...{
                      "href": "https://github.com/jetstack/cert-manager"
                    }}>{`cert-manager`}</a>{` for https`}<ul parentName="li">
                      <li parentName="ul">{`Way more complicated than GCE`}</li>
                    </ul></li>
                </ul></li>
            </ul></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "gcp"
    }}>{`GCP`}</h2>
    <ul>
      <li parentName="ul">{`Best kubernetes support`}</li>
      <li parentName="ul">{`Don't charge you for master nodes`}</li>
      <li parentName="ul">{`Quite expnsive in the end: ~60 usd/month`}<ul parentName="li">
          <li parentName="ul">{`Free credits will only last a couple months`}</li>
          <li parentName="ul">{`Costs about the same as Elastic Beanstalk in AWS`}</li>
          <li parentName="ul">{`Breakdown`}<ul parentName="li">
              <li parentName="ul">{`(all in Sydney)`}</li>
              <li parentName="ul">{`~\$25 usd/month for load balancer`}</li>
              <li parentName="ul">{`\$18.24 usd/month for 1 g1-small node`}</li>
              <li parentName="ul">{`~\$8 usd/month for storage, probably docker images`}</li>
              <li parentName="ul">{`\$8 usd/month for unused static ip address for staging environment`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`I like their focus on projects`}</li>
      <li parentName="ul">{`Their documentation has a focus on the CLI which made CI slightly easier`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloud.google.com/sdk/docs/quickstart-macos"
        }}>{`Install the CLI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stackoverflow.com/questions/43212836/multiple-environments-staging-qa-production-etc-with-kubernetes"
        }}>{`Staging Cluster vs Staging Namespace`}</a><ul parentName="li">
          <li parentName="ul">{`Chose a seperate staging cluster`}</li>
          <li parentName="ul">{`Easier`}</li>
          <li parentName="ul">{`More isolation`}<ul parentName="li">
              <li parentName="ul">{`Good for load testing`}</li>
              <li parentName="ul">{`Good for testing new Kubernetes versions`}</li>
            </ul></li>
          <li parentName="ul">{`I wanted to do a staging namespace so I could reuse the existing loadbalancer and save on costs but I couldn't work out how to do it`}<ul parentName="li">
              <li parentName="ul">{`Not so sure now, `}<a parentName="li" {...{
                  "href": "https://cloud.google.com/compute/all-pricing#lb"
                }}>{`might be per rule`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://github.com/kubernetes/ingress-gce/issues/369"
                }}>{`Reuse existing load balancer GitHub issue`}</a></li>
            </ul></li>
          <li parentName="ul">{`I turn it off after merging to save money at the cost of deploy time (~15 mins)`}<ul parentName="li">
              <li parentName="ul">{`They charge you ~\$8 usd/month for unused static ip addresses though`}</li>
              <li parentName="ul">{`It is very cool to spin up clusters as required though - infrastructure as code`}</li>
            </ul></li>
          <li parentName="ul">{`I had to contact support to get a 2nd static ip address though`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "github-actions"
    }}>{`Github Actions`}</h2>
    <ul>
      <li parentName="ul">{`I setup bash scripts to get CI working locally first`}<ul parentName="li">
          <li parentName="ul">{`Less vendor lockin`}</li>
          <li parentName="ul">{`Faster turnaround times`}</li>
          <li parentName="ul">{`Turns out bash isn't exactly portable`}<ul parentName="li">
              <li parentName="ul">{`GitHub Actions don't support `}<a parentName="li" {...{
                  "href": "https://mywiki.wooledge.org/BashFAQ/028"
                }}>{`Bash_Source`}</a></li>
              <li parentName="ul">{`GitHub Actions don't support `}<a parentName="li" {...{
                  "href": "https://vaneyckt.io/posts/safer_bash_scripts_with_set_euxo_pipefail/"
                }}>{`pipefail`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://stackoverflow.com/questions/5694228/sed-in-place-flag-that-works-both-on-mac-bsd-and-linux"
                }}>{`Sed is different on Mac`}</a></li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`Not many blog posts about it since it's still beta`}<ul parentName="li">
          <li parentName="ul">{`you can search for existing open source actions but a lot of them are wrong`}</li>
        </ul></li>
      <li parentName="ul">{`It took me ages to work out how to run the bash scripts inside the `}<a parentName="li" {...{
          "href": "https://github.com/actions/gcloud"
        }}>{`gcloud action`}</a><ul parentName="li">
          <li parentName="ul">{`you can pull other actions and debug locally since they're just docker files which is nice`}</li>
        </ul></li>
      <li parentName="ul">{`Docker commands behave diffently when run them inside a docker container`}<ul parentName="li">
          <li parentName="ul">{`GitHub Actions use bind mounts so you have to `}<a parentName="li" {...{
              "href": "https://stackoverflow.com/questions/55845723/volume-bind-mounting-from-docker-in-docker"
            }}>{`consider that when dealing with relative paths`}</a></li>
        </ul></li>
      <li parentName="ul">{`Was cool to submit a PR and have it deploy a staging cluster - infrastructure as code`}</li>
      <li parentName="ul">{`Pretty fast - builds in about 7mins`}</li>
      <li parentName="ul">{`Initially didn't support nested file filters but `}<a parentName="li" {...{
          "href": "https://github.blog/changelog/2019-09-30-github-actions-event-filtering-updates/"
        }}>{`now they do`}</a></li>
      <li parentName="ul">{`Squashing commits means the commit hash won't match from the PR and after you've merged so you have to build again after merging`}</li>
    </ul>
    <h2 {...{
      "id": "outstanding-issues"
    }}>{`Outstanding issues`}</h2>
    <ul>
      <li parentName="ul">{`Going to run out of GCP credits in ~5 months. Hopefully Azure is cheaper!`}</li>
    </ul>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <ul>
      <li parentName="ul">{`This was a fair bit of work. - Started in July and finished in September - Only have a few hours a week for my side projects`}</li>
      <li parentName="ul">{`Had to make a lot of uneducated decisions`}</li>
      <li parentName="ul">{`Really rewarding to finish it off and see it working`}</li>
      <li parentName="ul">{`Knowing my credits will expire in a couple months makes me sad. Hopefully it doesn't come around`}</li>
      <li parentName="ul">{`This whole thing really goes to show how terrible programming can be sometimes`}</li>
    </ul>
    <h2 {...{
      "id": "honerable-mentions"
    }}>{`Honerable Mentions`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.gitlab.com/ee/topics/autodevops/"
        }}>{`GitLab auto devops`}</a><ul parentName="li">
          <li parentName="ul">{`Would need to customise it a lot for a monorepo and testcontainers`}</li>
          <li parentName="ul">{`Expects a single container`}</li>
          <li parentName="ul">{`would have to leave Github or setup a mirror`}</li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.pulumi.com/kubernetes/"
        }}>{`Pulumi`}</a><ul parentName="li">
          <li parentName="ul">{`Infrastructure as code across platforms.`}</li>
          <li parentName="ul">{`Can provision services inside and outside of Kubernetes`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      